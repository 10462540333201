import React from "react";

function TicketFeatureIssue() {
  return (
    <div className="feature-issue">
      <h4>Feature Request</h4>
      <p>Status: </p>
      <p>Tracking Number: </p>
    </div>
  );
}

export default TicketFeatureIssue;
