import React from "react";

function TicketPaymentIssue() {
  return (
    <div className="payment-issue">
      <h4>Payment Issue</h4>
      <p>Status: </p>
      <p>Tracking Number: </p>
    </div>
  );
}

export default TicketPaymentIssue;
