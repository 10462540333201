import React from "react";
import "./ticket_list.scss";

function TicketLoginIssue() {
  return (
    <div className="login-issue">
      <h4>Login Issue</h4>
      <p>Status: </p>
      <p>Tracking Number: </p>
    </div>
  );
}

export default TicketLoginIssue;
