import React from 'react'
import ChatHeader from '../../components/chat-comp/chat-header/ChatHeader'
import ChatBottom from '../../components/chat-comp/chat-bottom/ChatBottom'
import ChatBody from '../../components/chat-comp/chat-body/ChatBody'

function Chat() {
  return (
    <>
        <ChatHeader />
        <ChatBody />
        <ChatBottom />
    </>
  )
}

export default Chat