import React from "react";

import "./signup.scss";
import SignUpForm from "../../components/signup-comp/signup-form/SignUpForm";

function Signup() {
  return (
    <>
      <SignUpForm />
    </>
  );
}

export default Signup;
