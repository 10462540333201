import React from 'react';
import InputField from '../../common/InputField';
import { GrAttachment } from "react-icons/gr";
import './chatbottom.scss';

function ChatBottom() {
  return (
    <div className='chat-bottom fixed-bottom'>
      <InputField
      type='text'
      name='message'
      placeholder='Type your message' 
      />
      <div className="icon">
        <GrAttachment />
      </div>
      <div className="icon">
        <GrAttachment />
      </div>
    </div>
  )
}

export default ChatBottom