import React, { useEffect, useState } from "react";
import "./ticket_list.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import TicketLoginIssue from "./TicketLoginIssue";
import TicketFeatureIssue from "./TicketFeatureIssue";
import TicketPaymentIssue from "./TicketPaymentIssue";
import { useSelector } from "react-redux";

function TicketList() {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [tickets, setTickets] = useState(null);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

    try {
      axios
        .get(`${process.env.REACT_APP_BACKEND_API}ticketlist/`)
        .then((res) => {
          console.log("res.data");
          console.log(res.data);
        })
        .then((data) => {
          console.log(data);
          setTickets(data);
        });
    } catch (e) {
      console.log("error");
      console.log(e.messages);
    }
  }, [accessToken]);

  return (
    <div className="ticket-list">
      <div className="buttons">
        <Link to="/ticket">Create Ticket</Link>
        <Link to="/chat">Chat Support</Link>
      </div>
      <div className="ticket-info">
        <h2>Your Tickets</h2>
        <TicketLoginIssue />
        <TicketFeatureIssue />
        <TicketPaymentIssue />
      </div>
    </div>
  );
}

export default TicketList;
