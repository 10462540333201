import React from 'react'
import './chatbody.scss';

function ChatBody() {
  return (
    <div className='chat-body'>
      
    </div>
  )
}

export default ChatBody